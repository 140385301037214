import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useCallback, useEffect } from "react";
import SmartDPSearchForm from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchForm";
import { fetchSDPFilters } from "app/pages/SmartDP/smartDPActionCreators";
import PropTypes from "prop-types";
import { getTopDestinations } from "app/reducers/shopSelector";
import { QUOTATION_CODE_STATUS, RESOLUTION, SDP_PRODUCTS_FILTERS_KEY } from "app/constants";
import { unsetSDPProductsFilter } from "app/pages/SmartDP/Listing/SmartDPFilters/smartDPProductsFilterActionCreators";
import { injectIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { buildSDPSearchPayload, normalizeDate } from "app/utils/smartDPUtils";
import withRouter from "app/utils/hocs/withRouter";
import { parse } from "qs";
import { getTravelLocationDataFromQuery } from "app/utils/smartDPUtils";
import { isEmpty } from "lodash";

const SmartDPSearchFormContainer = props => {
	const { fetchSDPFilters, unsetSDPProductsFilter } = props;

	const navigate = useNavigate();

	useEffect(() => {
		fetchSDPFilters();
	}, []);

	const handleFormSubmission = useCallback(({ values, pathname = "" }) => {
		const queryParams = buildSDPSearchPayload(values);
		unsetSDPProductsFilter(SDP_PRODUCTS_FILTERS_KEY.BUDGET);

		if (pathname.includes("/sdp/listing")) {
			navigate({ pathname: pathname, search: queryParams }, { replace: true });
		} else {
			navigate({ pathname: "/sdp/listing", search: queryParams });
		}
	}, []);

	return (
		<div className="sdp-search-form__container">
			<SmartDPSearchForm {...props} onSuccess={handleFormSubmission} />
		</div>
	);
};

SmartDPSearchFormContainer.propTypes = {
	fetchSDPFilters: PropTypes.func,
	unsetSDPProductsFilter: PropTypes.func,
	isMobile: PropTypes.bool,
	brand: PropTypes.string,
	disableFormPersistance: PropTypes.bool, // used to avoid fields flickering when coming from merch page with sdp form to home sdp
};

const mapStateToProps = (state, props) => {
	const queryParams = parse(props?.location?.search, { ignoreQueryPrefix: true });
	const { booking, smartDP } = state;
	const { disableFormPersistance } = props;
	const initialValues = {
		travelDates: {
			departureDate: "",
			endDate: "",
		},
	};

	if (smartDP?.cabins?.[0]) {
		initialValues.cabin = smartDP.cabins[0];
	}

	const {
		departure: departureFoundedFromQuery,
		destination: destinationFoundedFromQuery,
	} = getTravelLocationDataFromQuery({
		queryParams: {
			departureCityCode: queryParams?.departureCityCode,
			destinationResortCode: queryParams?.destinationResortCode,
			destinationIATA: queryParams?.destinationIATA,
		},
		destinations: smartDP?.destinations,
		departures: smartDP?.departureCities,
	});

	const {
		departure: departureFoundedFromBooking,
		destination: destinationFoundedFromBooking,
	} = getTravelLocationDataFromQuery({
		queryParams: {
			departureCityCode: booking?.departureCity?.code,
			destinationResortCode: booking?.destinationResort?.code,
		},
		destinations: smartDP?.destinations,
		departures: smartDP?.departureCities,
	});

	if (!disableFormPersistance && !isEmpty(destinationFoundedFromBooking)) {
		initialValues.destinationResort = destinationFoundedFromBooking;
	} else if (!isEmpty(destinationFoundedFromQuery)) {
		initialValues.destinationResort = destinationFoundedFromQuery;
	}

	if (!disableFormPersistance && !isEmpty(departureFoundedFromBooking)) {
		initialValues.departureCity = departureFoundedFromBooking;
	} else if (!isEmpty(departureFoundedFromQuery)) {
		initialValues.departureCity = departureFoundedFromQuery;
	}

	if (!disableFormPersistance) {
		if (booking?.departureDate) {
			initialValues.travelDates.departureDate = normalizeDate(booking.departureDate);
		}
		if (booking?.endDate) {
			initialValues.travelDates.endDate = normalizeDate(booking.endDate);
		}
		if (booking?.occupancies?.length > 0) {
			initialValues.occupancies = booking.occupancies;
		}
		if (booking?.duration?.value) {
			initialValues.duration = {
				code: booking.duration.value,
			};
		}
		if (booking?.cabin?.code) {
			initialValues.cabin = booking.cabin;
		}
	}

	return {
		initialValues,
		destinations: state.smartDP.destinations,
		departureCities: state.smartDP.departureCities,
		cabins: state.smartDP.cabins,
		validateOnMount: state.smartDP.status === QUOTATION_CODE_STATUS.BAD_REQUEST,
		topDestinationsList: getTopDestinations(state) || [],
		isMobile: [RESOLUTION.SMALL, RESOLUTION.MEDIUM].includes(state?.resolution),
		brand: state.brand.code,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchSDPFilters,
			unsetSDPProductsFilter,
		},
		dispatch
	);
};

export default withRouter(
	injectIntl(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(SmartDPSearchFormContainer)
	)
);
